import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { APP_CONFIG } from 'Config';

import LayoutHeader from 'layouts/LayoutHeader';
import Sidebar from 'layouts/Sidebar';
import Footer from 'layouts/Footer';
import PageTitle from 'components/partials/PageTitle';
import OtherAuctions from 'components/Auctions/OtherAuctions';
import NearbyAuctions from 'components/Auctions/NearbyAuctions';

import Thingamabob from 'components/Dev/Thingamabob';
import { screenResize, setPageContainerClass } from 'actions';
import UserStatusNotification from 'components/UserStatusNotification';
import SwitchClassic from 'components/SwitchClassic';
import LivePerson from 'layouts/LivePerson';

import 'styles/Main.less';
import 'styles/common.less';
import 'styles/vendors/toastr.less';
import SMSOptInAlert from '../components/SMSOptInAlert';

class MainLayout extends Component {
    state = {
        notification: false,
        element_pos_fixed: false,
        affix: false,
        affixOffset: 133,
    };

    componentDidMount = () => {
        // Initialize the container class
        this.eventWindowResize();

        // Setup window listener to automatically update the container class
        window.addEventListener('resize', this.eventWindowResize);
        window.addEventListener('scroll', this.eventWindowScroll);
    };

    componentWillUnmount = () => {
        // Remove window listener that automatically update the container class
        window.removeEventListener('resize', this.eventWindowResize);
        window.removeEventListener('scroll', this.eventWindowScroll);
    };

    eventWindowResize = () => {
        if (typeof window === 'undefined') {
            return false;
        }
        this.props.setPageContainerClass({containerClass: `container${window.innerWidth <= 1200 ? '-fluid' : ''}`});
        this.props.screenResize({width: window.innerWidth});
    };

    eventWindowScroll = () => this.setState({
        element_pos_fixed: window.scrollY < 260,
        affix: window.scrollY > this.state.affixOffset,
    });

    renderBidBanner = () => {
        const {bid_banner = false} = this.props;

        if (
            (typeof window === 'undefined' ||
            this.props?.children?.props?.location?.pathname !== '/auctions/[state]/[city]/[auction_id]') &&
            !bid_banner
        ) {
            return null;
        }

        const {type, subject, body} = bid_banner;
        if (!body && !subject) return null;

        //removed 'affix'
        const affixClass = this.state.affix ? ' affix-top' : '';

        return (
            <div className={`top-notification notification-${type} stick-top ${affixClass}`}>
                <span className="subject">{subject}</span>
                <span className="body">{body}</span>
            </div>
        );
    };

    render() {
        const {auction_id, user, showOtherAuctions = false} = this.props;
        const {element_pos_fixed} = this.state;

        const thingamabob = get(APP_CONFIG, 'DEV', false) ? <Thingamabob/> : null;
        const subComponent = auction_id ? <OtherAuctions show={showOtherAuctions}/> : null;
        const nearbyAuctions = auction_id ? <NearbyAuctions show={showOtherAuctions} /> : null;

        return (
            <div>
                <div className="content-container" id="content-container">
                    <Sidebar showTopMessageBar={this.props.showTopMessageBar} />
                    {this.props.showTopMessageBar && (
                        <div className={`top-switch-bar-top is-${element_pos_fixed ? 'fixed' : 'relative'}`}>
                            <SwitchClassic />
                        </div>
                    )}
                    <LayoutHeader />
                    <div className="pageContent">
                        <PageTitle />
                        {this.renderBidBanner()}
                        <div className="clearfix" />
                        <div className={this.props.containerClass}>
                            <SMSOptInAlert />
                            <UserStatusNotification />
                            {this.props.children}
                        </div>
                    </div>
                    {thingamabob}
                    {subComponent}
                    {nearbyAuctions}
                    <Footer />
                </div>
                <LivePerson />
            </div>
        );
    }
}

const mapStateToProps = ({ page, user }) => {
    const { bid_banner, user: current_user } = user;

    return {
        containerClass: page.data.containerClass,
        bid_banner,
        user: current_user,
    };
};

export default connect(mapStateToProps, { setPageContainerClass, screenResize })(MainLayout);
