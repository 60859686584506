import { useEffect, useState } from 'react'
import ManageInviteModal from 'components/AuthorizedPickupPerson/Modals/ManageInvite'
import ConfirmationModal from 'components/AuthorizedPickupPerson/Modals/Confirmation'
import useService from 'hooks/useService'
import ManageMembershipModal from 'components/ManageMembershipModal'
import { PUT } from 'services/RequestMethods'
import { general_error_toastr, general_success_toastr } from 'components/partials/Notification'
import { useGlobalContext } from 'context/GlobalContext'

const AfpInvitesWrapper = ({ children }) => {
    const { user: userData, getUser } = useGlobalContext()
    const [showAuthorizedPickupInvite, setshowAuthorizedPickupInvite] = useState(false)
    const [showInviteAcceptanceModal, setShowInviteAcceptanceModal] = useState(false)
    const [showInviteRejectionModal, setShowInviteRejectionModal] = useState(false)
    const [showManageMembershipModal, setShowManageMembershipModal] = useState(false)
    const [pendingInvites, setPendingInvites] = useState([])
    const isBasicBidder = userData?.membership?.type === 'Basic Bidder'

    const [{ data: { results: invites = [] } = {}, loading }, fireGetInvites] = useService()

    const [{ data, loading: managingInvite }, fireManageInvite] = useService()

    useEffect(() => {
        const fetchInvites = async () => {
            const response = await fireGetInvites({
                url: `users/${userData?.user_id}/authorized-invites`,
                params: {
                    scope: 'invitee'
                }
            })
            const filteredResults = response.results?.filter(invite => invite.status === 'Pending') || []
            setPendingInvites(filteredResults)

            if (filteredResults.length > 0) {
                setshowAuthorizedPickupInvite(true)
            }
        }

        if (userData?.reg_type == 2) {
            getUser()
            fetchInvites()
        }
    }, [userData?.reg_type])

    const manageInviteModalConfirm = () => {
        setshowAuthorizedPickupInvite(false)

        if (isBasicBidder) {
            setShowManageMembershipModal(true)
        } else {
            setShowInviteAcceptanceModal(true)
        }
    }

    const manageInviteModalReject = () => {
        setshowAuthorizedPickupInvite(false)
        setShowInviteRejectionModal(true)
    }

    const handleManageInvite = async (action) => {
        const manageInviteResponse = await fireManageInvite({
            url: `users/account/authorized-invites/${pendingInvites[0]?.invite_id}`,
            params: { action: action },
            method: PUT
        })

        if (manageInviteResponse?.message === 'OK') {
            general_success_toastr({ message: `Invite ${action === 'accept' ? 'accepted' : 'declined'}` })
            return true
        } else {
            general_error_toastr()
            return false
        }
    }

    const updateInvitesAndView = async () => {
        const response = await fireGetInvites({
            params: { scope: 'invitee' }
        })
        const filteredResults = response.results?.filter(invite => invite.status === 'Pending') || []
        setPendingInvites(filteredResults)

        if (filteredResults.length > 0) {
            setShowInviteAcceptanceModal(false)
            setShowInviteRejectionModal(false)
            setshowAuthorizedPickupInvite(true)
        } else {
            setShowInviteAcceptanceModal(false)
            setShowInviteRejectionModal(false)
        }
    }

    const handleConfirmInvite = async () => {
        const isSuccessful = await handleManageInvite('accept')
        if (isSuccessful) {
            updateInvitesAndView()
        } else {
            general_error_toastr()
        }
    }

    const handleRejectInvite = async () => {
        const isSuccessful = await handleManageInvite('decline')
        if (isSuccessful) {
            setShowInviteRejectionModal(false)
            updateInvitesAndView()
        } else {
            general_error_toastr()
        }
    }

    const membershipUpdateSuccess = async () => {
        setshowAuthorizedPickupInvite(true)
    }

    return (
        <>  
            {children}
            {userData?.reg_type == 2 && (
                <>
                    <ManageInviteModal
                        show={showAuthorizedPickupInvite}
                        setShow={setshowAuthorizedPickupInvite}
                        handleConfirm={manageInviteModalConfirm}
                        handleReject={manageInviteModalReject}
                        managePlan={isBasicBidder}
                        invite={pendingInvites[0]}
                    />
                    <ConfirmationModal
                        title="Confirm Acceptance"
                        text="Are you sure you want to accept this Authorized Pickup Person invite?"
                        submitLabel="Accept"
                        show={showInviteAcceptanceModal}
                        setShow={setShowInviteAcceptanceModal}
                        handleConfirm={handleConfirmInvite}
                        loading={managingInvite}
                    />
                    <ConfirmationModal
                        title="Confirm Decline"
                        text="Are you sure you want to decline this Authorized Pickup Person invite?"
                        submitLabel="Decline"
                        show={showInviteRejectionModal}
                        setShow={setShowInviteRejectionModal}
                        handleConfirm={handleRejectInvite}
                        loading={managingInvite}
                    />
                    <ManageMembershipModal
                        membership_type={userData?.membership?.type}
                        show={showManageMembershipModal}
                        onHide={() => setShowManageMembershipModal(false)}
                        user_id={userData?.user_id}
                        payment_method={userData?.payment_method}
                        handleMembershipSubmitSuccess={membershipUpdateSuccess}
                    />
                </>
            )}
        </>
    )
}

export default AfpInvitesWrapper
